import * as React from "react";
import { View } from "react-native";
import { Chip } from "react-native-paper";
import { StyleSheet } from "react-native";

import { White, primary } from "@bo/constants/colors";

export const styles = (isSelected: boolean) =>
  StyleSheet.create({
    chip: {
      backgroundColor: isSelected ? primary : White,
      borderRadius: 4,
      margin: 4,
    },
    text: {
      flexGrow: 1,
      textAlign: "center",
      color: isSelected ? White : "black",
    },
  });

export interface GridOption {
  id: number;
  option: string;
  isSelected: boolean;
  isProfileSelected: boolean;
}

export const stylesx = StyleSheet.create({
  holder: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export interface Props {
  options: GridOption[]; // The list of options to display
  onChange: (option: GridOption) => void; // Callback when an option changes
  editable: boolean; // Whether the grid is editable
  isTop?: boolean; // Indicates whether this is the "Top 3" section
  canRemove?: boolean; // Indicates if the option can be removed
}

export default function GridOptions({ options, editable, onChange, isTop, canRemove }: Props) {
  return (
    <View style={stylesx.holder}>
      {options.map((option) => {
        const isOptionSelected = isTop ? option.isProfileSelected : option.isSelected;

        return (
          <Chip
            key={option.id}
            selected={isOptionSelected}
            onPress={() => {
              if (!editable) return;
              onChange(option);
            }}
            showSelectedCheck={false}
            style={styles(isOptionSelected).chip}
            textStyle={styles(isOptionSelected).text}
            {...(editable && canRemove
              ? {
                  onClose: () => {
                    if (!editable) return;
                    onChange(option);
                  },
                }
              : {})}
          >
            {option.option}
          </Chip>
        );
      })}
    </View>
  );
}
