import * as React from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { View, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { NavigationParamList } from "../../navigation/RootStack";
import { White, textLight } from "@bo/constants/colors";
import { Chat } from "@bo/components/organism/Chat";

import DashboardTemplate from "./template/Template";
import Text from "@bo/components/common/Text";
import Button from "@bo/components/atom/Button";
import Input from "@bo/components/atom/Input";
import { useAuthContext } from "../../hooks/authHook";
import { useDeleteMessageRequest, useGetMessages, usePostMessageRequest } from "../../services/messageService";
import { useGetAppointmentById, usePutAppointmentRequest } from "../../services/appointmentsService";
import { ContentTheme } from "@bo/types";
import JoinVideoCallButton from "../call/JoinCallButton/JoinVideoCallButton";
import { useIsMobile } from "../../hooks/mobileHook";
import Toast from "react-native-toast-message";
import { formatCurrency } from "../../utils/text";

const desktopStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 64,
    paddingBottom: 32,
    borderColor: textLight,
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 64,
  },
  headerButtons: {
    flexDirection: "column",
    gap: 16,
  },
  contentContainer: {
    paddingTop: 32,
    gap: 32,
  },
  section: {
    paddingHorizontal: 64,
    paddingBottom: 32,
    borderBottomWidth: 1,
    borderColor: textLight,
    gap: 16,
  },
  sectionContent: {
    gap: 8,
  },
  chatContainer: {
    backgroundColor: White,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingBottom: 8,
  },
  messageInput: {
    height: 100,
    borderRadius: 16,
  },
});

const mobileStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 32,
    paddingBottom: 16,
    borderColor: textLight,
    borderBottomWidth: 1,
    gap: 8,
  },
  headerButtons: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8,
  },
  contentContainer: {
    paddingTop: 16,
    gap: 16,
  },
  section: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderColor: textLight,
    gap: 8,
  },
  sectionContent: {
    gap: 4,
  },
  chatContainer: {
    backgroundColor: White,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingBottom: 8,
  },
  messageInput: {
    height: 100,
    borderRadius: 16,
  },
});

export default function AppointmentScreen({ route }: NativeStackScreenProps<NavigationParamList, "Appointment">) {
  const { user, therapistProfile } = useAuthContext();
  const { i18n } = useTranslation();
  const locale = i18n.language === "sl" ? "sl-SI" : "en-US";
  const isMobile = useIsMobile();
  const [text, setText] = React.useState("");

  const styles = isMobile ? mobileStyles : desktopStyles;

  const {
    data: appointment,
    refetch: getAppointment,
    error: appointmentError,
  } = useGetAppointmentById(route.params.id);

  const { mutate: updateAppointment } = usePutAppointmentRequest(
    route.params.id,
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "spremembi statusa srečanja" }),
      });
    },
    () => {
      Toast.show({
        type: "success",
        text1: t("toasts.default-success-title"),
        text2: t("toasts.default-success-message", { description: "spremenil-a status srečanja" }),
      });
      getAppointment();
    },
  );

  const { data: messages, refetch: getMessages, error: messagesError } = useGetMessages(route.params.id);

  const { mutate: createMessage } = usePostMessageRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "pošiljanju sporočila" }),
      });
    },
    () => {
      Toast.show({
        type: "success",
        text1: t("toasts.default-success-title"),
        text2: t("toasts.default-error-message", { description: "poslal-a sporočilo" }),
      });
      setText("");
      getMessages();
    },
  );

  const { mutate: deleteMessage } = useDeleteMessageRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "pošiljanju sporočila" }),
      });
    },
    () => {
      Toast.show({
        type: "success",
        text1: t("toasts.default-success-title"),
        text2: t("toasts.default-error-message", { description: "izbrisal-a sporočilo" }),
      });
      getMessages();
    },
  );

  const onMessageSubmit = () => {
    if (user) {
      createMessage({
        text,
        senderId: user.id,
        appointmentId: route.params.id,
      });
    }
  };

  const onMessageDelete = (id: string) => {
    deleteMessage(id);
  };

  const formatDateTime = (date: Date) => {
    const str = new Date(date).toLocaleString(locale, {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getThemeTranslation = (theme: string) => {
    if (Object.values(ContentTheme).includes(theme as ContentTheme)) {
      return t(`content-themes.option-${theme.toLowerCase()}`);
    }

    return theme;
  };

  React.useEffect(() => {
    getMessages();
    getAppointment();
  }, []);

  React.useEffect(() => {
    if (appointmentError) {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "pridobivanju podatkov o srečanju" }),
      });
    }
  }, [appointmentError]);

  React.useEffect(() => {
    if (messagesError) {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "pridobivanju sporočil" }),
      });
    }
  }, [messagesError]);

  const isJoinAppointmentButtonDisabled = () => {
    if (!appointment?.data) {
      return true;
    }

    const now = new Date();
    const fromTime = new Date(appointment.data.from);

    const joinIntervalStart = new Date(fromTime.getTime() - 15 * 60000); // 15 minutes before the appointment starts
    const joinIntervalEnd = new Date(fromTime.getTime() + 15 * 60000); // 15 minutes after the appointment starts

    // Button should be enabled if the current time is within the interval from 15 minutes before to 15 minutes after the start
    return !(now >= joinIntervalStart && now <= joinIntervalEnd && appointment.data.status === "PENDING");
  };

  return (
    <DashboardTemplate>
      <View style={styles.titleContainer}>
        <Text type="H3">
          {t("screens.dashboard.appointment.title")} {appointment?.data.customer?.name}
        </Text>
        <View style={styles.headerButtons}>
          {appointment && user && appointment.data.status === "PENDING" && (
            <JoinVideoCallButton
              isDisabled={isJoinAppointmentButtonDisabled()}
              userId={user.id}
              image={""}
              name={user.name}
              role={user.role === "USER" ? "USER" : "THERAPIST"}
              buttonText={t("screens.dashboard.appointment.button-join-video-call")}
            />
          )}
          {appointment && appointment.data.status === "PENDING" && new Date() >= new Date(appointment.data.from) && (
            <Button type="secondary" onPress={() => updateAppointment({ status: "COMPLETED" })}>
              {t("screens.dashboard.appointment.button-mark-as-completed")}
            </Button>
          )}
        </View>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.section}>
          <Text type="P1.B">{t("screens.dashboard.appointment.title-details")}</Text>
          <View style={styles.sectionContent}>
            <Text type="P2">
              {t("screens.dashboard.appointment.row-status")}:{" "}
              {appointment?.data.status && t(`therapy-status.status-${appointment?.data.status.toLowerCase()}`)}
            </Text>
            <Text type="P2">
              {t("screens.dashboard.appointment.row-date")}:{" "}
              {appointment?.data.from && formatDateTime(appointment?.data.from)}
            </Text>
            <Text type="P2">
              {t("screens.dashboard.appointment.row-patient")}: {appointment?.data.customer?.name}
            </Text>
            <Text type="P2">
              {t("screens.dashboard.appointment.row-therapist")}: {user?.name} {therapistProfile?.surname}
            </Text>
            {therapistProfile && (
              <Text type="P2">
                {t("screens.dashboard.appointment.row-price")}: {formatCurrency(therapistProfile.appointmentPrice)}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section}>
          <Text type="P1.B">{t("screens.dashboard.appointment.title-patient-info")}</Text>
          <View style={styles.sectionContent}>
            <Text type="P2">
              {t("screens.dashboard.appointment.row-name")}: {appointment?.data.customer?.name}
            </Text>
            <Text type="P2">
              {t("screens.dashboard.appointment.row-age")}: {appointment?.data.customer?.age}{" "}
              {t("screens.dashboard.appointment.years")}
            </Text>
            <Text type="P2">
              {t("screens.dashboard.appointment.row-psychological-challenges")}:{" "}
              {appointment?.data.customer?.therapySelection?.themes
                ?.map((theme) => getThemeTranslation(theme))
                .join(", ")}
            </Text>
            <Text type="P2">
              {t("screens.dashboard.appointment.row-therapy-type")}:{" "}
              {appointment?.data.customer?.therapySelection?.therapyType &&
                t(`therapy-types.option-${appointment?.data.customer?.therapySelection?.therapyType.toLowerCase()}`)}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text type="P1.B">{t("screens.dashboard.appointment.title-messages")}</Text>
          {!!messages?.data.length && (
            <View style={styles.chatContainer}>
              <Chat messages={messages?.data || []} userId={user?.id || ""} onDelete={onMessageDelete} />
            </View>
          )}
          <View style={styles.sectionContent}>
            <Input
              placeholder={t("screens.dashboard.appointment.input-placeholder")}
              multiline={true}
              style={styles.messageInput}
              outlineStyle={styles.messageInput}
              value={text}
              onChange={setText}
            />
            <Button type="primary" onPress={onMessageSubmit}>
              {t("screens.dashboard.appointment.button-send")}
            </Button>
          </View>
        </View>
      </View>
    </DashboardTemplate>
  );
}
