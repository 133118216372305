import * as React from "react";
import { View, StyleSheet, Alert } from "react-native";
import { t } from "i18next";

import Text from "@bo/components/common/Text";
import Button from "@bo/components/atom/Button";
import GridOptions, { GridOption } from "./GridOptions";
import Input from "@bo/components/atom/Input";

const desktopStyles = StyleSheet.create({
  holder: {
    marginVertical: 32,
  },
  section: {
    flexDirection: "row",
    marginTop: 32,
    alignItems: "center",
    marginBottom: 16,
  },
  input: {
    flex: 1,
    marginRight: 8,
  },
  addMoreInput: {
    width: "100%",
    flexDirection: "row",
    marginVertical: 16,
    alignItems: "center",
  },
  label: {
    flex: 1,
  },
});

interface AccountThemesProps {
  options: GridOption[];
  onOptionsChange: (options: GridOption[]) => void;
}

export default function AccountThemes({ options, onOptionsChange }: AccountThemesProps) {
  const [nextOptionId, setNextOptionId] = React.useState<number>(options.length);
  const [newOptionText, setNewOptionText] = React.useState("");
  const [isEditingTop3, setIsEditingTop3] = React.useState(false);
  const [isEditingOthers, setIsEditingOthers] = React.useState(false);

  const handleNewOptionChange = (text: string) => setNewOptionText(text);

  const handleAddNewOption = () => {
    const trimmedOptionText = newOptionText.trim();
    if (!trimmedOptionText) return;

    const newOption = {
      id: nextOptionId,
      option: trimmedOptionText,
      isSelected: isEditingOthers,
      isProfileSelected: isEditingTop3,
    };

    onOptionsChange([...options, newOption]);
    setNextOptionId(nextOptionId + 1);
    setNewOptionText("");
  };

  const handleOptionToggle = (option: GridOption) => {
    const index = options.findIndex((o) => o.id === option.id);
    if (index !== -1) {
      if (isEditingTop3) {
        const currentIsProfileSelected = options[index].isProfileSelected;
        const newIsProfileSelected = !currentIsProfileSelected;

        const newOptions = options.map((o) =>
          o.id === option.id ? { ...o, isProfileSelected: newIsProfileSelected, isSelected: false } : o,
        );

        const selectedCount = newOptions.filter((o) => o.isProfileSelected).length;

        if (newIsProfileSelected && selectedCount > 3) {
          Alert.alert(t("screens.dashboard.account.themes-choose-top-3-alert"));
          return;
        }

        onOptionsChange(newOptions);
      } else if (isEditingOthers) {
        const currentIsSelected = options[index].isSelected;
        const newIsSelected = !currentIsSelected;

        const newOptions = options.map((o) =>
          o.id === option.id ? { ...o, isSelected: newIsSelected, isProfileSelected: false } : o,
        );
        onOptionsChange(newOptions);
      }
    }
  };

  // Handle editing state toggles
  const handleEdit = (toggle: boolean, isTop: boolean) => {
    if (isTop) {
      setIsEditingOthers(false);
      setIsEditingTop3(toggle);
    } else {
      setIsEditingTop3(false);
      setIsEditingOthers(toggle);
    }
  };

  interface SectionProps {
    label: string;
    isEditing: boolean;
    onEdit: () => void;
  }

  const Section = ({ label, isEditing, onEdit }: SectionProps) => (
    <View style={desktopStyles.section}>
      <Text type="P1" style={desktopStyles.label}>
        {label}
      </Text>
      {isEditing ? (
        <Button type="primary" onPress={onEdit}>
          {t("screens.dashboard.account.button-confirm")}
        </Button>
      ) : (
        <Button type="secondary" onPress={onEdit}>
          {t("screens.dashboard.account.button-edit")}
        </Button>
      )}
    </View>
  );

  return (
    <View style={desktopStyles.holder}>
      <Text type="P1.I">{t("screens.dashboard.account.themes-title")}</Text>

      <View style={desktopStyles.addMoreInput}>
        <Input
          style={desktopStyles.input}
          placeholder={t("screens.dashboard.account.button-add-more-placeholder")}
          value={newOptionText}
          onChange={handleNewOptionChange}
        />
        <Button type="primary" onPress={handleAddNewOption} buttonStyle={{ flex: 1 }}>
          {t("screens.dashboard.account.button-add-more")}
        </Button>
      </View>

      <GridOptions
        options={options.filter((opt) => !opt.isProfileSelected && !opt.isSelected)}
        onChange={handleOptionToggle}
        editable={isEditingTop3 || isEditingOthers}
      />

      <Section
        label={t("screens.dashboard.account.themes-choose-top-3")}
        isEditing={isEditingTop3}
        onEdit={() => handleEdit(!isEditingTop3, true)}
      />
      <GridOptions
        options={options.filter((opt) => opt.isProfileSelected)}
        onChange={handleOptionToggle}
        editable={isEditingTop3}
        canRemove={isEditingTop3}
        isTop
      />

      <Section
        label={t("screens.dashboard.account.themes-choose-others")}
        isEditing={isEditingOthers}
        onEdit={() => handleEdit(!isEditingOthers, false)}
      />
      <GridOptions
        options={options.filter((opt) => opt.isSelected)}
        onChange={handleOptionToggle}
        editable={isEditingOthers}
        canRemove={isEditingOthers}
      />
    </View>
  );
}
