import * as React from "react";
import { t } from "i18next";
import { Image, ImageSourcePropType, Pressable, View, StyleSheet } from "react-native";
import { grayTitle, textLight } from "@bo/constants/colors";

import DashboardTemplate from "./template/Template";
import Text from "@bo/components/common/Text";
import Button from "@bo/components/atom/Button";
import { InputFormControl } from "@bo/components/molecule/InputFormControl";
import { GroupedButtons } from "@bo/components/atom/GroupedButtons";
import { GroupedButtonsMultiselect } from "@bo/components/atom/GroupedButtonsMultiselect";
import GridOptions from "@bo/components/molecule/GridOptions";
import { blobToBase64 } from "@bo/utils";
import { useForm, Controller } from "react-hook-form";
import { usePostTherapistProfileMeRequest, usePutUserMeRequest } from "../../services/accountService";
import { useAuthContext } from "../../hooks/authHook";
import { ImagePickerAsset, MediaTypeOptions, launchImageLibraryAsync } from "expo-image-picker";
import { usePostAssetRequest } from "../../services/assetsService";
import { useProfileImage } from "@bo/hooks";
import { useIsMobile } from "../../hooks/mobileHook";
import Toast from "react-native-toast-message";
import { formatCurrency } from "../../utils/text";
import { GridOption } from "@bo/components/molecule/GridOptions/interface";
import { GridOption as ThemeGridOption } from "./GridOptions";
import AccountThemes from "./AccountThemes";
import { ContentTheme } from "@bo/types";

const desktopStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 64,
    paddingBottom: 32,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 64,
    paddingTop: 32,
    gap: 16,
  },
  multiFromControls: {
    flexDirection: "row",
    gap: 32,
  },
  multiFormControlsChild: {
    flexGrow: 1,
    flexShrink: 1,
  },
  multiline: {
    height: 120,
    borderRadius: 16,
  },
  controlWithTitleContainer: {
    gap: 16,
  },
  imageContainer: {
    aspectRatio: 16 / 9,
    borderWidth: 1,
    borderColor: textLight,
    borderRadius: 16,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 16,
    objectFit: "cover",
    // @ts-expect-error Web style only
    objectPosition: "center",
  },
  imagePlacholderContainer: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: 32,
  },
  button: {
    marginTop: 64,
  },
  formControlTitle: {
    color: grayTitle,
  },
});

const mobileStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 32,
    paddingBottom: 16,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
    gap: 16,
  },
  multiFromControls: {
    gap: 16,
  },
  multiFormControlsChild: {},
  multiline: {
    height: 120,
    borderRadius: 16,
  },
  controlWithTitleContainer: {
    gap: 8,
  },
  imageContainer: {
    aspectRatio: 16 / 9,
    borderWidth: 1,
    borderColor: textLight,
    borderRadius: 16,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 16,
    objectFit: "cover",
    // @ts-expect-error Web style only
    objectPosition: "center",
  },
  imagePlacholderContainer: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
  button: {
    marginTop: 32,
  },
  formControlTitle: {
    color: grayTitle,
  },
});

export default function AccountScreen() {
  const form = useForm();
  const currentProfileImage = useProfileImage();
  const isMobile = useIsMobile();
  const { user, therapistProfile, refetchMe, setTherapistProfile } = useAuthContext();
  const [pickedProfileImage, setPickedProfileImage] = React.useState<ImagePickerAsset>();
  const [imageSource, setImageSource] = React.useState<ImageSourcePropType>();
  const [isImageUploaded, setIsImageUploaded] = React.useState(false);
  const [titleOptions, setTitleOptions] = React.useState<GridOption[]>([
    {
      option: t("therapy-types.option-individual"),
      isSelected: false,
    },
    {
      option: t("titles.option-psychotherapist"),
      isSelected: false,
    },
    {
      option: t("titles.option-psychotherapy-and-supercivision-specialist"),
      isSelected: false,
    },
    {
      option: t("titles.option-psychotherapy-specialist"),
      isSelected: false,
    },
    {
      option: t("titles.option-clinical-psychology-specialist"),
      isSelected: false,
    },
    {
      option: t("titles.option-child-specialist"),
      isSelected: false,
    },
    {
      option: t("titles.option-intern"),
      isSelected: false,
    },
  ]);
  const initialOptions: ThemeGridOption[] = Object.values(ContentTheme).map((challenge, idx) => ({
    id: idx,
    option: t(`content-themes.option-${challenge.toLowerCase()}`),
    isSelected: false,
    isProfileSelected: false,
  }));
  const [options, setOptions] = React.useState<ThemeGridOption[]>(initialOptions);
  const styles = isMobile ? mobileStyles : desktopStyles;

  const { mutate: updateUser } = usePutUserMeRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "shranjevanju podatkov" }),
      });
    },
    () => {
      refetchMe();
    },
  );
  const { mutate: updateProfile } = usePostTherapistProfileMeRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "shranjevanju podatkov" }),
      });
    },
    (data) => {
      Toast.show({
        type: "success",
        text1: t("toasts.default-success-title"),
        text2: t("toasts.default-success-message", { description: "shranil-a nove podatke" }),
      });
      setTherapistProfile(data.data);
    },
  );
  const { mutate: updateProfileImage } = usePostAssetRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "shranjevanju podatkov" }),
      });
    },
    (data) => {
      setIsImageUploaded(true);
      updateUser({ profileImageAssetId: data.data.id });
    },
  );

  const selectProfileImage = async () => {
    const image = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!image.assets) {
      return;
    }

    setPickedProfileImage(image.assets[0]);
  };

  const genderOptions = [
    {
      label: t("genders.option-male"),
      value: "Male",
    },
    {
      label: t("genders.option-female"),
      value: "Female",
    },
  ];

  const therapyTypeOptions = [
    {
      label: t("therapy-types.option-individual"),
      value: "Individual",
    },
    {
      label: t("therapy-types.option-couple"),
      value: "Couple",
    },
    {
      label: t("therapy-types.option-family"),
      value: "Family",
    },
    {
      label: t("therapy-types.option-therapyforchildren"),
      value: "TherapyForChildren",
    },
  ];

  React.useEffect(() => {
    if (pickedProfileImage?.uri) {
      setImageSource({ uri: pickedProfileImage.uri });
      return;
    }

    if (currentProfileImage?.data) {
      blobToBase64(currentProfileImage.data)
        .then((base64) => {
          setImageSource({ uri: base64 });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [currentProfileImage, pickedProfileImage]);

  React.useEffect(() => {
    setIsImageUploaded(false);
  }, [pickedProfileImage]);

  React.useEffect(() => {
    if (user && therapistProfile) {
      form.setValue("firstName", user.name);
      form.setValue("lastName", therapistProfile.surname);
      form.setValue("title", therapistProfile.title);
      form.setValue("subtitle", therapistProfile.subTitle);
      form.setValue("about", therapistProfile.aboutDescription);
      form.setValue("approach", therapistProfile.approachDescription);
      form.setValue("appointmentDuration", therapistProfile.appointmentDuration);
      form.setValue("appointmentPrice", therapistProfile.appointmentPrice);
      form.setValue("gender", therapistProfile.gender);
      form.setValue("therapyType", therapistProfile.therapyType);

      const initialOptionStrings = new Set(initialOptions.map((option) => option.option));
      const allProfileOptions = [...therapistProfile.therapyChallenges, ...therapistProfile.therapyTopChallenges];
      const customOptionStrings = allProfileOptions.filter((option) => !initialOptionStrings.has(option));
      const customOptions = customOptionStrings.map((option, idx) => ({
        id: initialOptions.length + idx,
        option,
        isSelected: false,
        isProfileSelected: false,
      }));
      const allOptions = [...initialOptions, ...customOptions];

      // Initialize option states
      const updatedOptions = allOptions.map((option) => {
        const isSelected = therapistProfile.therapyChallenges.includes(option.option);
        const isProfileSelected = therapistProfile.therapyTopChallenges.includes(option.option);
        return {
          ...option,
          isSelected,
          isProfileSelected,
        };
      });
      const titleIndex = titleOptions.findIndex((option) => option.option === therapistProfile.title);
      if (titleIndex !== -1) {
        const newOptions = [...titleOptions];
        newOptions.forEach((option) => (option.isSelected = false));
        newOptions[titleIndex].isSelected = true;
        setTitleOptions(newOptions);
      }
      setOptions(updatedOptions);
    } else if (user) {
      form.setValue("firstName", user.name);
    }
  }, [user, therapistProfile]);
  const onSubmit = () => {
    if (pickedProfileImage && !isImageUploaded) {
      const fileUrlArray = pickedProfileImage.uri.split(",");
      const binaryString = atob(fileUrlArray[fileUrlArray.length - 1]);
      const uIntArr = new Uint8Array(binaryString.length);

      let n = binaryString.length;

      while (n--) {
        uIntArr[n] = binaryString.charCodeAt(n);
      }

      const file = new File([uIntArr], pickedProfileImage.fileName ?? `profile-image-${new Date()}`);

      updateProfileImage({
        name: file.name,
        file: file,
      });
    }

    updateUser({
      name: form.getValues().firstName,
    });

    updateProfile({
      surname: form.getValues().lastName,
      title: form.getValues().title,
      subTitle: form.getValues().subtitle,
      aboutDescription: form.getValues().about,
      approachDescription: form.getValues().approach,
      appointmentDuration: parseInt(form.getValues().appointmentDuration),
      appointmentPrice: parseFloat(form.getValues().appointmentPrice),
      therapyType: form.getValues().therapyType,
      gender: form.getValues().gender,
      therapyChallenges: options.filter((option) => option.isSelected).map((option) => option.option),
      therapyTopChallenges: options.filter((option) => option.isProfileSelected).map((option) => option.option),
    });
  };

  const getValueIndex = (options: { label: string; value: string }[], value: string) => {
    const index = options.findIndex((option) => option.value === value);

    if (index === -1) {
      return undefined;
    }

    return index;
  };

  const getValueIndexes = (options: { label: string; value: string }[], values: string[]) => {
    return values.map((value) => options.findIndex((option) => option.value === value)).filter((index) => index !== -1);
  };

  const handleTitleOptionToggle = (option: GridOption, index: number) => {
    const newOptions = [...titleOptions];
    newOptions.forEach((option) => (option.isSelected = false));
    newOptions[index] = option;
    setTitleOptions(newOptions);
  };

  return (
    <DashboardTemplate>
      <View style={styles.titleContainer}>
        <Text type="H3">{t("screens.dashboard.account.title")}</Text>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.multiFromControls}>
          <View style={styles.multiFormControlsChild}>
            <InputFormControl
              inputProps={{
                placeholderAsLabel: true,
                placeholder: t("screens.dashboard.account.field-first-name"),
                value: form.watch("firstName"),
              }}
              controllerProps={{
                control: form.control,
                name: "firstName",
              }}
            />
          </View>
          <View style={styles.multiFormControlsChild}>
            <InputFormControl
              inputProps={{
                placeholderAsLabel: true,
                placeholder: t("screens.dashboard.account.field-last-name"),
                value: form.watch("lastName"),
              }}
              controllerProps={{
                control: form.control,
                name: "lastName",
              }}
            />
          </View>
        </View>
        <Controller
          control={form.control}
          name="gender"
          render={({ field: { onChange } }) => (
            <GroupedButtons
              options={genderOptions.map((option) => option.label)}
              isHorizontal={!isMobile}
              onChange={(_, index) => onChange(genderOptions[index].value)}
              defaultValueIndex={getValueIndex(genderOptions, form.watch("gender"))}
            />
          )}
        />
        <View style={styles.controlWithTitleContainer}>
          <Pressable style={styles.imageContainer} onPress={selectProfileImage}>
            {imageSource ? (
              <Image source={imageSource} style={styles.image} />
            ) : (
              <View style={styles.imagePlacholderContainer}>
                <Text type="P1.B">{t("screens.dashboard.account.image-placeholder-first-row")}</Text>
                <Text type="P1">{t("screens.dashboard.account.image-placeholder-second-row")}</Text>
              </View>
            )}
          </Pressable>
        </View>
        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-title")}
        </Text>
        <Controller
          control={form.control}
          name="title"
          render={({ field: { onChange } }) => (
            <GridOptions
              options={titleOptions}
              onChange={(option, index) => {
                handleTitleOptionToggle(option, index);
                onChange(titleOptions[index].option);
              }}
            />
          )}
        />
        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-subtitle")}
        </Text>
        <InputFormControl
          inputProps={{
            placeholder: t("screens.dashboard.account.field-subtitle-placeholder"),
            value: form.watch("subtitle"),
          }}
          controllerProps={{
            control: form.control,
            name: "subtitle",
          }}
        />

        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-about")}
        </Text>
        <InputFormControl
          inputProps={{
            placeholder: t("screens.dashboard.account.field-about"),
            multiline: true,
            style: styles.multiline,
            outlineStyle: styles.multiline,
            value: form.watch("about"),
          }}
          controllerProps={{
            control: form.control,
            name: "about",
          }}
        />

        <AccountThemes options={options} onOptionsChange={setOptions} />

        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-therapy-hours-title")}
        </Text>
        <View style={styles.multiFromControls}>
          <View style={styles.multiFormControlsChild}>
            <InputFormControl
              inputProps={{
                placeholderAsLabel: true,
                placeholder: `${t("screens.dashboard.account.field-appointment-duration")} (min)`,
                value: form.watch("appointmentDuration"),
              }}
              controllerProps={{
                control: form.control,
                name: "appointmentDuration",
              }}
            />
          </View>
          <View style={styles.multiFormControlsChild}>
            <InputFormControl
              inputProps={{
                placeholderAsLabel: true,
                placeholder: `${t("screens.dashboard.account.field-appointment-price")} (€)`,
                value: formatCurrency(form.watch("appointmentPrice")),
              }}
              controllerProps={{
                control: form.control,
                name: "appointmentPrice",
              }}
            />
          </View>
        </View>
        <View style={styles.controlWithTitleContainer}>
          <Text type="P2.B" style={styles.formControlTitle}>
            {t("screens.dashboard.account.field-therapy-types")}
          </Text>
          <Controller
            control={form.control}
            name="therapyType"
            render={({ field: { onChange } }) => (
              <GroupedButtonsMultiselect
                options={therapyTypeOptions.map((option) => option.label)}
                isHorizontal={!isMobile}
                onChange={(_, indexes) => onChange(indexes.map((index) => therapyTypeOptions[index].value))}
                defaultValueIndexes={getValueIndexes(therapyTypeOptions, form.watch("therapyType") ?? [])}
              />
            )}
          />
        </View>
        <Button type="primary" buttonStyle={styles.button} onPress={onSubmit}>
          {t("screens.dashboard.account.button")}
        </Button>
      </View>
    </DashboardTemplate>
  );
}
